
.title{
    width: 15vw;
    height: 20vh;
    font-size: 1em;
    text-align: center;
}

.name{
    width: 100%;
    height: 70%;
    font-size: 6rem;
    font-weight: bold;
    background: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}