.content{
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.section{
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
}

.title{
    font-size: 4rem;
    background-image: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: bottom left;
}


.card{
    margin-left: 350px;
    width: 50%;
    height: 80%;
    background: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    border-radius: 20px;
    box-shadow: -8px -8px 60px 1px rgba(98, 0, 255, 0.404),
                8px 8px 30px 1px rgb(219, 219, 219, 20%);
    padding: 40px;
    box-sizing: border-box;
    font-size: 1.1em;
    line-height: 2em;
    color: #18171b;
    font-weight: 600;
    display: flex;
    align-items: center;
}