.content{
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.section{
    margin-top: 130px;
    width: 100%;
    height: 70%;
}

.sub{
    display: flex;
    justify-content: center;
}

.title{
    width: 16%;
    font-size: 4rem;
    background-image: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: bottom left;
    transition: background-size 300ms ease;
    height: 11%;
    margin: 60px;
}

.twrapper a:link,
.twrapper a:visited,
.twrapper a:active{
    text-decoration: none;
    background-image: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    background-size: 0% 3px;
    background-repeat: no-repeat;
    background-position: bottom left;
    transition: background-size 300ms ease;
    color: white;
    font-weight: bold;
}

.twrapper a:hover{
    background-size: 100% 3px;
}

.wrapper{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    height: 60%;
}

.twrapper{
    height: 30%;
}

.pwrapper{
    height: 70%;
}