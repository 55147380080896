.content{
    margin-top: 200px;
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.section{
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
}

.wrapper{
    width: 60%;
    height: 80%;
    margin-left: 300px;
    display: flex;
}

.wrapper ul{
    margin-top: 80px;
}

.title{
    font-size: 4rem;
    background-image: linear-gradient(251.33deg, #6100FF -20.81%, #DBDBDB 123.65%);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: bottom left;
}